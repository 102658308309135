<template>
  <div class="page flex-col">
    <header>
      <div class="bgImg">
        <img src="./assets/img/header_logo.png" alt="">
      </div>
      <div class="nearbyStore">
        <van-nav-bar
          title="门店信息"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        />
        <div class="nearbyStoreInfo">
          <div class="storeJieshao">
            <img src="./assets/img/store_img.png" alt="">
            <div class="detail">
              <h3>度爽餐饮（大智路店）</h3>
              <div class="rate">
                <img src="./assets/img/fullStar.png" alt="">
                <img src="./assets/img/halfStar.png" alt="">
              </div>
              <div class="businessHours">
                <span>营业</span>
                <span>8:00 ~ 19:00</span>
              </div>
            </div>
          </div>
          <div class="storeAddress">
            地址：武汉市洪山区XXXXXXXXX1号商铺
          </div>
        </div>
      </div>
    </header>
    <section>
      <div class="nearbyBar">
        <ul>
          <router-link to="/storesInfo/caipins" >
            <li @click="handleDishes" :class="isActive?'active':''">
              菜品
            </li>
          </router-link>
          <router-link to="/storesInfo/storeInfoItem" >
            <li @click="handleDishes" :class="isActive?'active':''">
              商家
            </li>
          </router-link>
        </ul>
      </div>
      <div class="showDishes">
        <router-view></router-view>
      </div>
    </section>
    <!-- <footer>3</footer> -->
  </div>
</template>
<script>

export default {
  data() {
    return {
      isActive:0,
     
      constants: {}
    };
  },
  mounted(){
    let pathName = window.location.pathname
    if(pathName == '/storesInfo'){
      this.$router.push('/storesInfo/dishes')
    }
    console.log('location',window.location.pathname);
  },
  methods: {
    // 返回
    onClickLeft(){
      this.$router.go(-1)
    },
    handleDishes(){
      console.log('666');
    }
  }
};
</script>
<style scoped lang="css" >
* {
    padding: 0;
    margin: 0;
  }
  html,
  body {
    font-size: 37.5px;
    height: 100%;
  }
  
  .page {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .flex-row{
    display: flex;
  }
  header {
    height: 14.2rem;
    position: relative;
    position: relative;
    background-color: #fb743a;
  }
  
  footer {
    height: 3.95rem;
  }
  
  section {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  .bgImg img{
    width:100%;
    height: 11.2rem;
  }
  .nearbyStore{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  ::v-deep .van-nav-bar{
    background-color: transparent;
  }
  .nearbyStoreInfo{
    margin: 0 1.33rem;
    padding: 1.33rem 1.06rem 1rem;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 20px;
    margin-bottom: 1.04rem;
  }
  .storeJieshao{
    display: flex;
  }
  .storeJieshao>img{
    width: 6.93rem;
    height: 4.8rem;
    margin-right: 0.8rem;
  }
  .detail{
    display: flex;
    flex-direction: column;
  }
  .detail h3{
    height: 1.38rem;
    line-height: 1.38rem;
    font-size: 1.06rem;
    color: #333;
  }
  .detail .rate img{
    width: 1.12rem;
    height: 1.12rem;
  }
  .businessHours{
    margin-top: 0.3rem;
  }
  .businessHours span:nth-child(1){
    display: inline-block;
    padding: 0.13rem 0.32rem;
    font-size: 0.69rem;
    color:#fff;
    background-color: #fc7a3d;
    border-radius: 3px;
  }
  .businessHours span:nth-child(2){
    font-size: 0.69rem;
    color:#999;
    margin-left: 0.53rem;
  }
  .storeAddress{
    margin-top: 0.8rem;
    font-size: 0.8rem;
    color: #999;;
  }
  .nearbyBar{
    padding-left: 1.06rem;
    height: 4.3rem;
  }
  .nearbyBar ul{
    display: flex;
  }
  .nearbyBar li{
    width: 3.73rem;
    height: 4rem;
    line-height: 4rem;
    color: #666;
    font-size: 1.22rem;
    text-align: center;
  }
  .showDishes{
    flex: 1;
    overflow: hidden;
  }
  .router-link-active{
    color: #fb743a;
    font-size: 1.22rem;
    border-bottom: 0.26rem solid #fb743a;
    /* border-radius: 5px 5px; */
    box-sizing: border-box;
    text-align: center;
  }
  .router-link-active li{
    color: #fb743a;
    font-size: 1.22rem;
  }
  ::v-deep .van-nav-bar .van-icon{
    color: #fff;
  }
  ::v-deep .van-nav-bar__title{
    color: #fff;
  }
</style>